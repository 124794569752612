export const StorageRoutes = {
  /* GET */
  storageAddresses: `storage/addresses/`

  /* POST */

  /* PUT */

  /* DELETE */
}
