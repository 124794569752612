import PrimaryDialog from '@/@core/components/dialog/PrimaryDialog'
import { CheckIconSquare } from '@/@core/icons'
import { LocationEditIcon } from '@/@core/icons/LocationEditIcon'
import { useStorageAddresses } from '@/api/api-hooks'
import { OPTION } from '@/components/book-storage-facility/bookNowForm/includes/constant'
import FormError from '@/components/book-storage-facility/bookNowForm/includes/FormError'
import StepCard from '@/components/book-storage-facility/bookNowForm/includes/StepCard'
import AutoCompleteField from '@/components/subComponents/AutoCompleteField'
import Configs from '@/configs'
import { cn } from '@/lib/utils'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Tooltip } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import axios from 'axios'
import { has, isEmpty, isObject } from 'lodash'
import moment from 'moment'
import { forwardRef, useState } from 'react'
import ContentLoader from 'react-content-loader'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const DatePickerInput = forwardRef(({ onChange, className, placeholder, name, value, id, onClick }, ref) => {
  return (
    <input
      type='text'
      name={name}
      ref={ref}
      className={className}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      id={id}
      onClick={onClick}
      autoComplete='off'
    />
  )
})

const Loader = props => {
  return (
    <ContentLoader
      width='100%'
      height='100%'
      viewBox='0 0 350 600'
      backgroundColor='#f5f5f5'
      foregroundColor='#dbdbdb'
      {...props}
    >
      <rect x='4' y='8' rx='3' ry='3' width='8' height='570' />
      <rect x='5' y='573' rx='3' ry='3' width='331' height='7' />
      <rect x='329' y='9' rx='3' ry='3' width='8' height='570' />
      <rect x='102' y='69' rx='3' ry='3' width='102' height='7' />
      <rect x='92' y='47' rx='3' ry='3' width='178' height='6' />
      <circle cx='48' cy='63' r='18' />
      <rect x='95' y='95' rx='3' ry='3' width='178' height='6' />
      <rect x='105' y='169' rx='3' ry='3' width='102' height='7' />
      <rect x='95' y='147' rx='3' ry='3' width='178' height='6' />
      <circle cx='51' cy='163' r='18' />
      <rect x='98' y='195' rx='3' ry='3' width='178' height='6' />
      <rect x='107' y='265' rx='3' ry='3' width='102' height='7' />
      <rect x='97' y='243' rx='3' ry='3' width='178' height='6' />
      <circle cx='53' cy='259' r='18' />
      <rect x='100' y='291' rx='3' ry='3' width='178' height='6' />
      <rect x='108' y='365' rx='3' ry='3' width='102' height='7' />
      <rect x='98' y='343' rx='3' ry='3' width='178' height='6' />
      <circle cx='54' cy='359' r='18' />
      <rect x='101' y='391' rx='3' ry='3' width='178' height='6' />
      <rect x='110' y='458' rx='3' ry='3' width='102' height='7' />
      <rect x='100' y='436' rx='3' ry='3' width='178' height='6' />
      <circle cx='56' cy='452' r='18' />
      <rect x='103' y='484' rx='3' ry='3' width='178' height='6' />
      <rect x='114' y='507' rx='3' ry='3' width='102' height='7' />
      <rect x='103' y='534' rx='3' ry='3' width='178' height='6' />
      <rect x='5' y='8' rx='3' ry='3' width='331' height='7' />
    </ContentLoader>
  )
}

const FormLeft = function (props) {
  const { mutateAsync, isPending } = useStorageAddresses()
  const [isCountryChanged, setIsCountryChanged] = useState(false)
  const [openStorageAddressModal, setOpenStorageAddressModal] = useState(false)

  const serviceType = props.formData.serviceType

  const setCountry = (country, stateKey) => {
    if (props.formData.country_from.country_id !== country.country_id) {
      setIsCountryChanged(true)
    }

    if (stateKey === 'from') {
      props.setFormData({
        ...props.formData,
        country_from: country,
        state: ''
      })
    }
    if (stateKey === 'to') {
      props.setFormData({
        ...props.formData,
        country_to: country,
        state_to: ''
      })
    }
  }

  const setWarehouse = warehouse => {
    const prevErrors = props.formErrors
    delete prevErrors.warehouse
    props.setFormErrors(prevErrors)

    // props.setFormData({ ...props.formData, warehouse })

    mutateAsync({ warehouseId: warehouse?.id })
      .then(res => {
        if (res.statusCode === 200) {
          const resData = res?.data?.data
          props.setFormData({
            ...props.formData,
            warehouse: warehouse,
            storageAddresses: resData,
            selectedStorageAddress: null
          })
          setOpenStorageAddressModal(true)
        }
      })
      .catch(err => console.log(err))
  }

  const handleSelectedStorageAddress = selectedLocation => {
    props.setFormData({ ...props.formData, selectedStorageAddress: selectedLocation })
    setOpenStorageAddressModal(false)
  }

  const getStateByZipCode = async e => {
    const prevErrors = props.formErrors
    delete prevErrors.state
    props.setFormErrors(prevErrors)

    if (props.formData?.country_from?.country_id === 226 && e.target.value.toString().length > 2) {
      let zipCode = e.target.value

      const instance = axios.create({
        baseURL: Configs.apiBaseURL
      })

      let result = await instance
        .get('zip/' + zipCode)
        .then(response => response)
        .catch(err => err)
      if (result.status === 200) {
        return result.data.data
      }
    } else {
      props.setFormData({
        ...props.formData,
        state: e.target.value,
        stateValidate: true
      })
    }

    return []
  }

  const getStateByZipCodeTo = async e => {
    const prevErrors = props.formErrors
    delete prevErrors.state_to
    props.setFormErrors(prevErrors)

    if (props.formData?.country_to?.country_id === 226 && e.target.value.toString().length > 2) {
      let zipCode = e.target.value

      const instance = axios.create({
        baseURL: Configs.apiBaseURL
      })

      let result = await instance
        .get('zip/' + zipCode)
        .then(response => response)
        .catch(err => err)
      if (result.status === 200) {
        return result.data.data
      }
    } else {
      props.setFormData({
        ...props.formData,
        state_to: e.target.value,
        stateValidate: true
      })
    }

    return []
  }

  const handleChangeCountryTo = e => {
    const prevErrors = props.formErrors
    delete prevErrors.country_to
    props.setFormErrors(prevErrors)

    setCountry(e, 'to')
  }

  const handleShippingDate = date => {
    const prevErrors = props.formErrors
    delete prevErrors.pickUpDate
    props.setFormErrors(prevErrors)

    setPickUpDate(date)
  }

  const setZipcode = (item, stateKey) => {
    if (stateKey === 'from') {
      props.setFormData({
        ...props.formData,
        state: item,
        stateValidate: true
      })
    }
    if (stateKey === 'to') {
      props.setFormData({
        ...props.formData,
        state_to: item,
        stateValidate: true
      })
    }
  }

  const setPickUpDate = item => {
    // let date = item.getFullYear()+'-'+ (item.getMonth() + 1)+'-'+item.getDate() ;
    let date = item ? moment(item).format('YYYY-MM-DD') : ''
    props.setFormData({
      ...props.formData,
      pickUpDate: date,
      stateValidate: true
    })
  }

  const setShippingType = (e, value) => {
    let defaultCountry = false

    if (value === 0) {
      defaultCountry = {
        country_id: 226,
        country: 'United States (USA)',
        iso2: 'US',
        iso3: 'USA',
        calling_code: '1'
      }
    }

    if (serviceType !== value) {
      props.setFormData({
        ...props.formData,
        country_to: defaultCountry,
        state_to: '',
        warehouse: false,
        selectedStorageAddress: null,
        serviceType: value
      })
    }
  }

  const storageSelectField = () => {
    const selectClassName = has(props.formErrors, 'warehouse') ? 'select-two-wrapper has-error' : 'select-two-wrapper'

    const commonSelectProps = {
      isLoading: isPending,
      isDisabled: isPending,
      onChange: setWarehouse,
      options: props.warehouses,
      className: selectClassName,
      components: animatedComponents,
      placeholder: 'Select Storage Location'
    }

    return (
      <Select
        {...commonSelectProps}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.title}
        defaultValue={props.formData.warehouse}
        value={props.warehouses && props.formData.warehouse ? props.formData.warehouse : false}
      />
    )
  }

  const getShippingOnlyFields = () => {
    return (
      <>
        <div className='input-group mb-0'>
          {props.countries ? (
            <Select
              className={has(props.formErrors, 'country_to') ? 'select-two-wrapper has-error' : 'select-two-wrapper'}
              components={animatedComponents}
              defaultValue={props.formData.country_to ? props.formData.country_to : false}
              value={props.formData.country_to ? props.formData.country_to : false}
              getOptionLabel={option => option.country}
              getOptionValue={option => option.country_id}
              options={props.countries}
              onChange={handleChangeCountryTo}
            />
          ) : (
            ''
          )}
          <FormError message={props?.formErrors?.country_to?.[0]} />
        </div>
        <div className='input-group'>
          <AutoCompleteField
            key='skjdhfkjhsdfk'
            className={
              has(props.formErrors, 'state_to') || has(props.formErrors, 'city_from')
                ? 'select-two-wrapper has-error'
                : 'select-two-wrapper'
            }
            isCountryChanged={isCountryChanged}
            getData={getStateByZipCodeTo}
            onChange={e => setZipcode(e, 'to')}
            getLabel={item => item.zip + ' / ' + item.primary_city + ' / ' + item.primary_city}
            getValue={item => {
              if (isObject(item) && !isEmpty(item)) {
                return item.zip + ' - ' + item.primary_city
              }

              return item
            }}
            validated={() => {}}
            defaultValue={props.formData.state_to ? props.formData.state_to : false}
          />
          <FormError message={props?.formErrors?.state_to} />
          <FormError message={props?.formErrors?.city_to?.[0]} />
        </div>
      </>
    )
  }

  const animatedComponents = makeAnimated()

  return (
    <>
      {props.loading ? (
        <Loader />
      ) : (
        <>
          <div
            className={`flex flex-col gap-3
                            [&_input]:!font-semibold
                            [&_.select-two-wrapper]:!font-semibold
                            [&_input]:!text-sm
                            [&_.select-two-wrapper]:!text-sm
                            [&_.select-two-wrapper]:placeholder:!text-sm
                            [&_#react-select-3-placeholder]:!text-sm
                            xxs:[&_input]:!text-sm
                            xxs:[&_input]:placeholder:!text-sm
                            xxs:[&_.select-two-wrapper]:!text-sm
                            xxs:[&_#react-select-3-placeholder]:!text-sm
                            xs:[&_input]:!text-base
                            xs:[&_input]:placeholder:!text-base
                            xs:[&_.select-two-wrapper]:!text-base
                            xs:[&_#react-select-3-placeholder]:!text-base
                            `}
          >
            <StepCard
              stepData={{
                step: 1,
                title: 'Select Storage or Shipping',
                info: {
                  content: (
                    <>
                      <p className='text-sm13 text-center'>
                        Select your convenient storage. Your luggage/boxes will be shipped to our smart storage
                        facility.
                      </p>
                      <p className='text-sm13 text-center'>
                        6 months FREE storage if you use our shipping services from you to our warehouse and from our
                        warehouse back to you.
                      </p>
                      <p className='text-sm13 text-center'>
                        Or you can directly walk to our storage facility to store your items. To do so, please select
                        "drop off to storage" option.
                      </p>
                    </>
                  )
                }
              }}
            >
              <div className='input-group mb-2 sm-mx:mb-2.5 storage-select-group'>
                <div className='labels-group xxs:space-x-0 xs:space-x-8'>
                  <label className='checkbox cursor-pointer xxs:gap-1 xs:gap-1.5'>
                    <Checkbox
                      name='storage'
                      checked={serviceType === OPTION.SHIPPING_TO}
                      onChange={e => setShippingType(e, OPTION.SHIPPING_TO)}
                    />
                    <span className='title xxs:!text-xs xs:!text-sm'>
                      Ship to
                      <br /> Storage
                    </span>
                  </label>

                  <Tooltip title={'Coming soon.!'} arrow>
                    <label className='checkbox cursor-pointer xxs:gap-1 xs:gap-1.5'>
                      <Checkbox
                        name='dropoff'
                        checked={serviceType === OPTION.DROP_OFF}
                        onChange={e => setShippingType(e, OPTION.DROP_OFF)}
                        disabled
                      />
                      <span className='title xxs:!text-xs xs:!text-sm'>
                        Drop Off <br /> to Storage
                      </span>
                    </label>
                  </Tooltip>

                  <label className='checkbox cursor-pointer xxs:gap-1 xs:gap-1.5'>
                    <Checkbox
                      name='shipping_only'
                      checked={serviceType === OPTION.SHIPPING_ONLY}
                      onChange={e => setShippingType(e, OPTION.SHIPPING_ONLY)}
                    />
                    <span className='title xxs:!text-xs xs:!text-sm'>
                      Shipping <br /> Only
                    </span>
                  </label>
                </div>
              </div>
            </StepCard>

            {[OPTION.SHIPPING_TO, OPTION.SHIPPING_ONLY].includes(serviceType) && (
              <StepCard
                stepData={{
                  step: 2,
                  title: 'Send / Ship From',
                  info: {
                    content: (
                      <>
                        <p className='text-sm13 text-center'>
                          Please select your country and zip code (or city) you are shipping from. Zip code (or city) is
                          not required for international shipments.
                        </p>
                      </>
                    )
                  }
                }}
              >
                <div className='input-group '>
                  {props.countries ? (
                    <Select
                      className='select-two-wrapper'
                      components={animatedComponents}
                      defaultValue={props.formData.country_from ? props.formData.country_from : props.countries[0]}
                      value={props.formData.country_from ? props.formData.country_from : props.countries[0]}
                      getOptionLabel={option => option.country}
                      getOptionValue={option => option.country_id}
                      options={props.countries}
                      onChange={e => setCountry(e, 'from')}
                      styles={{
                        control: baseStyles => ({
                          ...baseStyles,
                          height: '38px'
                        })
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <AutoCompleteField
                    key='pouiteorut'
                    className={
                      has(props.formErrors, 'state') || has(props.formErrors, 'city_from')
                        ? 'select-two-wrapper has-error'
                        : 'select-two-wrapper'
                    }
                    style={{ height: '500px' }}
                    getData={getStateByZipCode}
                    isCountryChanged={isCountryChanged}
                    onChange={e => setZipcode(e, 'from')}
                    getLabel={item => item?.zip + ' / ' + item?.primary_city + ' / ' + item?.full_state}
                    getValue={item => {
                      if (isObject(item) && !isEmpty(item)) {
                        return item.zip + ' - ' + item.primary_city
                      }

                      return item
                    }}
                    validated={() => {}}
                    defaultValue={props.formData.state ? props.formData.state : false}
                    placeholder='Enter Zip Code or City'
                  />
                  <FormError message={props?.formErrors?.state} />
                  <FormError message={props?.formErrors?.city_from?.[0]} />
                </div>
              </StepCard>
            )}

            {[OPTION.SHIPPING_ONLY].includes(serviceType) && (
              <StepCard
                stepData={{
                  step: 3,
                  title: 'Ship To / Destination',
                  info: {
                    content: (
                      <>
                        <p className='text-sm13 text-center'>
                          Please select your country and zip code (or city) you are shipping from. Zip code (or city) is
                          not required for international shipments.
                        </p>
                      </>
                    )
                  }
                }}
              >
                {getShippingOnlyFields()}
              </StepCard>
            )}

            {[OPTION.SHIPPING_TO, OPTION.DROP_OFF].includes(serviceType) && (
              <StepCard
                stepData={{
                  step: serviceType === OPTION.DROP_OFF ? 2 : 3,
                  title: 'Select Storage Location',
                  info: {
                    content: (
                      <>
                        <p className='text-sm13 text-center'>
                          Please select your country and zip code (or city) you are shipping from. Zip code (or city) is
                          not required for international shipments.
                        </p>
                      </>
                    )
                  }
                }}
              >
                <div className='input-group'>
                  {storageSelectField()}
                  <FormError message={props?.formErrors?.warehouse} />
                </div>

                {!!props.formData.selectedStorageAddress && (
                  <>
                    <div className={'mt-3 flex justify-between items-center'}>
                      <div>
                        <p className={'xxs:!text-sm xs:!text-base !text-sm leading-normal text-black font-semibold'}>
                          {props.formData.selectedStorageAddress?.organization}
                        </p>
                        <p className={'xxs:!text-sm xs:!text-base !text-sm leading-normal text-black'}>
                          {props.formData.selectedStorageAddress?.city},{' '}
                          {props.formData.selectedStorageAddress?.zipcode},{' '}
                          {props.formData.selectedStorageAddress?.country_iso2}
                        </p>
                      </div>
                      <div>
                        <IconButton className={'p-0'} onClick={() => setOpenStorageAddressModal(true)}>
                          <LocationEditIcon />
                        </IconButton>
                        {/*<IconButton onClick={() => setOpenStorageAddressModal(true)}>
                                                  <ClearIcon color={'error'} />
                                                </IconButton>*/}
                      </div>
                    </div>
                  </>
                )}

                {props.formData.warehouse?.id && !props.formData.selectedStorageAddress && (
                  <div className={'mt-3'}>
                    <button
                      type={'button'}
                      className={'flex justify-between items-center'}
                      onClick={() => setOpenStorageAddressModal(true)}
                    >
                      <LocationOnIcon color={'info'} className={'mr-1'} />
                      <span className={'text-blue-600 border-b border-blue-500'}>Choose Storage Address</span>
                    </button>

                    {!props.formErrors.warehouse && !!props.formErrors.selectedStorageAddress && (
                      <span className='text-red-500 font-semibold mt-2'>{props.formErrors.selectedStorageAddress}</span>
                    )}
                  </div>
                )}
              </StepCard>
            )}

            <StepCard
              stepData={{
                step: serviceType === OPTION.DROP_OFF ? 3 : 4,
                title: serviceType === OPTION.DROP_OFF ? 'Drop Off Date' : 'Shipping Date',
                info: {
                  content:
                    serviceType === OPTION.DROP_OFF ? (
                      <p className='text-sm13 text-center'>
                        Please remember that our warehouse will receive your box/luggage Monday-Saturday except
                        holidays. To know more about our different storages, click here.
                      </p>
                    ) : (
                      <>
                        <p className='text-sm13 text-center'>
                          Select the exact date when your luggage can be picked up by our carriers or dropped off at a
                          carrier’s location. Pick up services are not available Sundays and holidays.
                        </p>
                        <p className='text-sm13 text-center'>
                          FedEx Saturday pick-up services are available for express shipments within the USA.
                        </p>
                        <p className='text-sm13 text-center'>
                          If your shipment is dropped off after the cut-off time of the courier's business hours, your
                          packages will be shipped out the next business day.
                        </p>
                      </>
                    )
                }
              }}
            >
              <div className='input-group'>
                <div className={'bg-white'}>
                  <DatePicker
                    name='pickup_date'
                    className={`${has(props.formErrors, 'pickUpDate') ? 'has-error ' : ''} h-[38px]`}
                    placeholderText='Select Date'
                    selected={props.formData.pickUpDate ? new Date(props.formData.pickUpDate) : null}
                    dateFormat='yyyy-MM-dd'
                    minDate={moment().toDate()}
                    onChange={handleShippingDate}
                    customInput={<DatePickerInput />}
                  />
                </div>
                <FormError message={props?.formErrors?.pickUpDate} />
              </div>
            </StepCard>
          </div>
        </>
      )}

      {openStorageAddressModal && (
        <PrimaryDialog
          openModal={openStorageAddressModal}
          closeModal={() => setOpenStorageAddressModal(false)}
          title={'Select Your Storage Address'}
          sx={{ ['.MuiDialog-container']: { alignItems: 'center !important' } }}
        >
          <div className={'flex flex-col gap-3'}>
            {!!props.formData.storageAddresses?.length &&
              props.formData.storageAddresses.map(location => {
                const selected = props?.formData?.selectedStorageAddress?.id === location?.id

                return (
                  <>
                    <div
                      className={cn(
                        'relative flex flex-col justify-center items-center border-2 border-gray-400 rounded-lg p-2',
                        {
                          'bg-green-200': selected
                        }
                      )}
                    >
                      {selected && (
                        <div className={'absolute right-[-8px] top-[-8px]'}>
                          <CheckIconSquare width={20} height={20} />
                        </div>
                      )}

                      <h2 className={'font-semibold text-black'}>{location?.organization}</h2>
                      <p className={'mb-2 text-sm'}>
                        {location.city}, {location.zipcode}, {location.country_iso2}
                      </p>

                      <button
                        onClick={() => handleSelectedStorageAddress(location)}
                        className={cn(
                          'bg-blue-400 px-4 py-1 rounded-lg text-white font-semibold text-sm hover:bg-blue-700',
                          {
                            'bg-gray-400 hover:bg-gray-400 cursor-not-allowed': selected
                          }
                        )}
                        disabled={selected}
                      >
                        Use this Location
                      </button>
                    </div>
                  </>
                )
              })}
          </div>
        </PrimaryDialog>
      )}
    </>
  )
}

export default FormLeft
