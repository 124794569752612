export const CustomDocumentRoutes = {
  /* GET */
  orderDetails: 'order/get-international-order-custom-documents-info/',

  /* POST */
  updateTravelItinerary: 'order/update-travel-itinerary-info/',
  updateItemList: 'order/update-international-order-item-list/',
  uploadKycInfo: 'order/upload-international-order-kyc-document-file/',
  uploadPassportInfo: 'order/upload-international-order-passport-file-info/',
  updateSupportingDocuments: 'order/update-international-order-supporting-document/',

  uploadSignature: 'order/upload-international-item-list-signature/',
  uploadItemListFiles: 'order/upload-international-item-list-order-form-file-info/',

  updateSupportingDocument: 'order/update-international-order-supporting-document/',
  uploadPassportOrVisa: 'order/upload-international-order-passport-file-info/',
  uploadKyc: 'order/upload-international-order-kyc-document-file/',
  uploadTravel: 'order/upload-travel-itinerary-file/',
  updateTravel: 'order/update-travel-itinerary-info/',

  /* PUT */
  shippingPurpose: 'order/update-shipping-purpose/',

  /* PATCH */
  checkPopup: 'order/update-int-popup-check/',
  minMaxPopup: 'order/update-customs-popup-checks/',

  /* DELETE */
  deleteDocument: 'order/delete-international-item-list-order-form-file-info/',
  deletePassport: 'order/delete-international-order-passport-file-info/',
  deleteKyc: 'order/delete-international-order-supporting-document-kyc-file/',
  deleteTravel: 'order/delete-travel-itinerary-file/'
}
