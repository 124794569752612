import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { DialogProps } from '@mui/material/Dialog'

import dynamic from 'next/dynamic'

// Dynamically import Material-UI components
const Dialog = dynamic(() => import('@mui/material/Dialog'), { ssr: false })
const DialogContent = dynamic(() => import('@mui/material/DialogContent'), { ssr: false })
const IconButton = dynamic(() => import('@mui/material/IconButton'), { ssr: false })

interface Props extends Omit<DialogProps, 'title' | 'open'> {
  openModal: boolean
  closeModal: () => void
  children: React.ReactNode
  title: string | React.ReactNode
  willHaveAction?: boolean
}

export default function PrimaryDialog({ openModal, closeModal, title, willHaveAction, children, ...rest }: Props) {
  const handleClose: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    closeModal()
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby='bootstrap-dialog-title'
        open={openModal}
        {...rest}
        sx={{
          zIndex: 11111,
          '& .MuiDialogContent-root': {
            minWidth: 320,
            maxWidth: 340
          },
          '& .MuiDialogActions-root': {},
          ['.MuiDialog-container']: { alignItems: 'center' },
          ['.MuiPaper-root']: { borderRadius: '12px' },
          ...(rest?.sx || {})
        }}
      >
        <div id={'bootstrap-dialog-title'} className={'font-semibold md:text-lg m-0 p-2 text-blue-strong'}>
          {title}
        </div>
        <IconButton
          aria-label='close'
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 4,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CancelIcon fontSize={'medium'} color={'error'} />
        </IconButton>
        <DialogContent dividers sx={{}}>
          {children}
        </DialogContent>
        {willHaveAction && (
          <div className={'flex justify-center items-center my-2'}>
            <button className={'py-1 px-6 rounded-sm bg-blue-strong font-semibold text-white'} onClick={closeModal}>
              Okay
            </button>
          </div>
        )}
      </Dialog>
    </>
  )
}
