import * as React from 'react'
import { SVGProps } from 'react'

export function NoteIcon(props: SVGProps<any>) {
  return (
    <svg width={40} height={40} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M28.092 4.029h4.042c.806 0 1.46.596 1.46 1.332v30.978c0 .736-.654 1.332-1.46 1.332H7.867c-.806 0-1.46-.596-1.46-1.332V5.361c0-.736.654-1.332 1.46-1.332h4.042'
        fill='#ECBA78'
      />
      <path
        d='M32.133 38.4H7.867c-1.25 0-2.267-.927-2.267-2.068V5.36c0-1.14 1.017-2.068 2.267-2.068h4.042c.443 0 .806.331.806.736 0 .405-.363.736-.806.736H7.867c-.355 0-.653.265-.653.596v30.978c0 .324.29.596.653.596h24.266c.355 0 .654-.265.654-.596V5.361c0-.324-.29-.596-.654-.596h-4.042c-.443 0-.806-.331-.806-.736 0-.405.363-.736.806-.736h4.042c1.25 0 2.267.927 2.267 2.068v30.978c0 1.134-1.016 2.061-2.267 2.061z'
        fill='#000'
      />
      <path
        d='M20 11.543h-9.382c-.444 0-.807-.33-.807-.736 0-.404.363-.736.807-.736H20c.444 0 .807.332.807.736 0 .405-.363.736-.807.736zm9.382 5.439H10.618c-.444 0-.807-.33-.807-.736 0-.404.363-.736.807-.736h18.764c.444 0 .807.332.807.736 0 .405-.363.736-.807.736zm0 5.447H10.618c-.444 0-.807-.331-.807-.736 0-.405.363-.736.807-.736h18.764c.444 0 .807.331.807.736 0 .405-.363.736-.807.736zm0 5.446H10.618c-.444 0-.807-.331-.807-.736 0-.405.363-.736.807-.736h18.764c.444 0 .807.331.807.736 0 .405-.363.736-.807.736zm0 5.447H10.618c-.444 0-.807-.332-.807-.736 0-.405.363-.736.807-.736h18.764c.444 0 .807.33.807.736 0 .404-.363.736-.807.736z'
        fill='#000'
      />
      <path
        d='M26.236 5.722H13.773c-1.025 0-1.856-.759-1.856-1.693 0-.935.83-1.693 1.856-1.693h12.463c1.025 0 1.856.758 1.856 1.693 0 .934-.831 1.693-1.856 1.693z'
        fill='#E3A76F'
      />
      <path
        d='M26.236 6.458H13.773c-1.469 0-2.663-1.09-2.663-2.43 0-1.339 1.194-2.428 2.663-2.428h12.463c1.469 0 2.663 1.09 2.663 2.429 0 1.34-1.194 2.429-2.663 2.429zM13.773 3.072c-.581 0-1.05.427-1.05.957s.469.957 1.05.957h12.463c.581 0 1.05-.427 1.05-.957s-.469-.957-1.05-.957H13.773z'
        fill='#000'
      />
    </svg>
  )
}
