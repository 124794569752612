import React from 'react'
import ContentPopover from '@/components/subComponents/popover/contentPopover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { has, isEmpty } from 'lodash'
import { assets } from '@/helpers/common'

const TabSkis = function (props) {
  let className = !isEmpty(props.className)
    ? 'single-tab-content row gulf_bag_row ' + props.className
    : 'single-tab-content row gulf_bag_row'

  function getSelectOption(keyOne, keyTwo) {
    let selectOptions = [...Array(11).keys()].map(i => {
      return (
        <option key={i} value={i}>
          {i}
        </option>
      )
    })

    return (
      <select
        style={{ cursor: 'pointer' }}
        className='input-field'
        defaultValue={props.formData.items[keyOne][keyTwo]}
        onChange={e => onSelectItem(e, keyOne, keyTwo)}
      >
        {selectOptions}
      </select>
    )
  }

  function onSelectItem(event, keyOne, keyTwo) {
    let newData = { ...props.formData }

    newData.items[keyOne][keyTwo] = parseInt(event.target.value)
    props.setFormData(newData)

    props.removeItemCountError()
  }

  return (
    <div className={className}>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/ski-boot-bag.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  20 x 15 x 14 inch <br />
                  51 x 38 x 36 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Ski Boot Bag'</b> type based on your packed ski boot bag total weight and
                size. If the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/boot_skis.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Ski Boot Bag</h5>
            <p>Max 20" x 15" x 14"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 25 lbs / 11 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('skis', 'small')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/ski-bag.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  73 x 8 x 8 inch <br />
                  185 x 20 x 20 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Ski Bag'</b> type based on your packed ski bag total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/ski_skis.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Ski Bag</h5>
            <p>Max 73" x 8" x 8"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 25 lbs / 11 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('skis', 'standard')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/double-ski-bag.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  76 x 12 x 10 inch <br />
                  193 x 30 x 25 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Ski Bag'</b> type based on your packed ski bag total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/double_skis.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Double Ski Bag</h5>
            <p>Max 76" x 12" x 10"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 40 lbs / 18 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('skis', 'large')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/small-snowboard.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  65 x 16 x 8 inch <br />
                  165 x 41 x 20 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Snowboard Bag'</b> type based on your packed snowboard bag total weight
                and size. If the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/small_skis.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Small Snowboard</h5>
            <p>Max 65" x 16" x 8"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 30 lbs / 14 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('skis', 'delux')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/large-snowboard.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  72 x 16 x 12 inch <br />
                  183 x 41 x 30 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Snowboard Bag'</b> type based on your packed snowboard bag total weight
                and size. If the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/large_pro_skis.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Large Snowboard</h5>
            <p>Max 72" x 16" x 12"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 50 lbs / 23 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('skis', 'large_pro')}
        </div>
      </div>
    </div>
  )
}

export default TabSkis
