import { useMutation } from '@tanstack/react-query'
import sendRequest from '@/api/sendRequest'
import { PublicRoutes } from '@/api/routes'

export const useCheckPriceList = () => {
  return useMutation({
    mutationKey: ['check-price-list'],
    mutationFn: async (formData: any) => {
      return await sendRequest({
        method: 'POST',
        payload: formData,
        url: PublicRoutes.checkPriceList
      })
    }
  })
}
