import { assets } from '@/helpers/common'
import { useEffect, useRef, useState } from 'react'

const AutoCompleteField = props => {
  const { placeholder = 'Zip Code or City' } = props

  const [states, setStates] = useState([])
  const [showState, setShowState] = useState(false)
  const [loading, setLoading] = useState(false)
  const autocompleteInput = useRef(null)
  const secRef = useRef()

  useEffect(() => {
    setStates([])
  }, [props.isCountryChanged])

  const onSelectState = e => {
    let valIndex = Number(e.target.dataset.id)
    props.onChange(states[valIndex])
    setShowState(false)
    autocompleteInput.current.value = props.getValue(states[valIndex])
  }

  const getAutoCompleteVew = () => {
    if (showState && states.length > 0) {
      let listItems = states.map((item, index) => {
        return (
          <li key={index} data-id={index} onClick={onSelectState}>
            {props.getLabel(item)}
          </li>
        )
      })

      return <ul className='autocomplete-list'>{listItems}</ul>
    }
  }

  const getData = async e => {
    setStates([])
    props.onChange(e.target.value)
    setLoading(true)
    let data = await props.getData(e)
    setStates(data)
    setLoading(false)
    setShowState(true)
  }

  const setDefaultValue = () => {
    if (props.defaultValue) {
      autocompleteInput.current.value = props.getValue(props.defaultValue)
    }
  }

  const handleClickOutside = event => {
    // if(has(props, 'validated')){
    //     props.validated();
    // }
    if (!secRef.current.contains(event.target) && showState) {
      setShowState(false)
    } else if (secRef.current.contains(event.target) && !showState) {
      setShowState(true)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false)
    }
  }, [secRef, showState])

  useEffect(() => {
    if (!props.defaultValue) {
      autocompleteInput.current.value = ''
    } else {
      autocompleteInput.current.value = props.defaultValue
    }
  }, [props.defaultValue])

  useEffect(() => {
    setDefaultValue()
  }, [autocompleteInput, props.defaultValue])

  const className = props.className

  return (
    <div
      className={className ? 'relative autocomplete-filed ' + className : 'relative autocomplete-filed'}
      ref={secRef}
    >
      <input type='hidden' value='' />
      <input
        type='text'
        className='input-field mt-3 w-full h-[38px]'
        ref={autocompleteInput}
        placeholder={placeholder}
        onChange={getData}
      />
      {loading ? <img alt='loading' className='loading' src={assets('images/loading.svg')} /> : ''}
      {getAutoCompleteVew()}
    </div>
  )
}

export default AutoCompleteField
