import React, { ReactNode, useState } from 'react'
import PlayIcon from '~/public/icons/play-video.svg'
import Image from 'next/image'
import PrimaryDialog from '@/@core/components/dialog/PrimaryDialog'
import dynamic from 'next/dynamic'
import { cn } from '@/lib/utils'

// Dynamically import ReactPlayer
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

type Props = {
  title: string | ReactNode
  className?: string
  description?: string
  videoProvider?: string
  videoLink?: string
}

export default function PlayHowToVideo({ title, className }: Props) {
  const [showVideo, setShowVideo] = useState(false)

  const videoLink = 'https://www.youtube.com/embed/JxQ1fyvpnpM'

  return (
    <>
      <PrimaryDialog
        openModal={showVideo}
        closeModal={() => setShowVideo(false)}
        title={title}
        sx={{
          '& .MuiDialogContent-root': {
            padding: '6px'
          },
          ['.MuiDialog-container']: {
            alignItems: 'center !important'
          }
        }}
        PaperProps={{
          sx: {
            minWidth: {
              xs: '350px',
              sm: '600px',
              md: '850px',
              lg: '1170px'
            }
            // width: '90%', // Width for smaller screens
          }
        }}
      >
        <div className={'aspect-[16/9]'}>
          <ReactPlayer
            url={videoLink}
            playing={showVideo} // Set to true to auto-start the video
            controls={true}
            width='100%'
            height='100%'
            config={{
              youtube: {
                playerVars: {
                  showinfo: 1,
                  playsinline: 1
                }
              }
            }}
          />
        </div>
      </PrimaryDialog>

      <div
        className={cn(
          'flex h-[42px] lg:min-w-[172px] max-w-[180px] lg:max-w-auto px-2 items-center justify-center text-left gap-2 border-orange-primary border rounded-lg cursor-pointer group bg-white hover:bg-[#0a9cff] hover:border-[#0a9cff] transition duration-200 ease-in-out',
          className
        )}
        // onClick={() => setShowVideo(prev => !prev)}
      >
        <p
          id={'play-how-to-video'}
          className={
            'basis-[calc(100%-42px)] text-[#333] font-medium text-xs group-hover:text-white transition duration-200 ease-in-out'
          }
        >
          {title}
        </p>
        <button
          type={'button'}
          className={'basis-[34px] m-0 p-0 border-none bg-transparent focus:border-none shadow-none'}
          aria-labelledby={'play-how-to-video'}
        >
          <Image src={PlayIcon} alt='Play Video Icon' width={34} height={28} />
        </button>
      </div>
    </>
  )
}
