import * as React from 'react'
import { SVGProps } from 'react'

export function RepackIcon(props: SVGProps<any>) {
  return (
    <svg width={50} height={50} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M25.001 47.967l23.226-7.989V10.022L25.001 2.034 1.775 10.022v29.956l23.226 7.989z' fill='#DFB28B' />
      <path d='M1.775 10.022l23.226 7.988v29.957L1.775 39.978V10.022z' fill='#F0D0B4' />
      <path d='M25 18.01l23.226-7.988L25 2.034 1.774 10.022 25 18.01z' fill='#CF9E76' />
      <path
        d='M8.742 12.418v6.59l8.516 2.997v-6.658l-8.516-2.929zm0 29.957l8.516 2.929V37.98l-8.516-2.995v7.389z'
        fill='#CF9E76'
      />
      <path d='M8.845 12.454l8.227 2.83 23.312-7.96-8.227-2.83-23.312 7.96z' fill='#B27946' />
      <path
        d='M25 1L1 9.255v31.491l23.8 8.185.2.069 24-8.255V9.255L25 1zm0 15.977l-4.156-1.43 20.221-6.955 4.156 1.43L25 16.977zm-13.447-4.625l20.221-6.956 6.286 2.162-20.221 6.956-6.286-2.162zm-2.037 1.366l6.968 2.397v4.582l-6.968-2.451v-4.528zM28.77 4.363L8.55 11.318l-3.77-1.296L25 3.067l3.769 1.296zM2.55 11.322l5.419 1.864v6.585l10.064 3.54v-6.664l6.194 2.13v27.89l-6.194-2.13v-7.318L7.968 33.678v7.397l-5.42-1.864V11.32zm13.935 32.681l-6.968-2.396v-5.313l6.968 2.45v5.26zm9.29 2.663V18.777l21.678-7.456v27.89l-21.678 7.456z'
        fill='#000'
      />
      <path
        d='M44.355 35.984h1.548v1.997h-1.548v-1.997zm-3.098.999h1.548v1.997h-1.548v-1.997zm-3.096.998h1.548v1.997h-1.548v-1.997z'
        fill='#000'
      />
    </svg>
  )
}
