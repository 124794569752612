export const PublicRoutes = {
  /* GET */
  countries: 'countrys',

  /* POST */
  checkPriceList: 'order/price/check'

  /* PUT */

  /* DELETE */
}
