import * as React from 'react'
import { SVGProps } from 'react'

export function LocationEditIcon(props: SVGProps<any>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.632 6.043a2.59 2.59 0 00-2.589 2.59c.142 3.433 5.036 3.433 5.178 0a2.59 2.59 0 00-2.589-2.59'
        fill='#2E7AEB'
      />
      <path
        d='M16.016 10.66C17.309 5.85 13.629.96 8.632 1 4.424 1 1 4.424 1 8.632c0 1.4.389 2.941 1.155 4.579.6 1.284 1.436 2.633 2.481 4.012 2.227 2.848 3.262 3.668 3.996 4.364.57-.526.508-.433 1.486-1.434v-3.601zm-7.384 1.77a3.803 3.803 0 01-3.8-3.799c.21-5.04 7.391-5.039 7.6 0a3.803 3.803 0 01-3.8 3.8'
        fill='#2E7AEB'
      />
      <path
        d='M12.52 15.939v1.952l1.953-.004 5.492-5.492-1.948-1.948zm9.522-7.569c-.512-.534-1.434-.534-1.947 0l-1.222 1.221 1.947 1.947 1.222-1.221a1.38 1.38 0 000-1.947'
        fill='#2E7AEB'
      />
      <path
        d='M16.144 21.212c0 .162-.088.387-.408.663-.318.273-.812.545-1.47.785-1.312.477-3.161.782-5.23.782s-3.92-.305-5.231-.782c-.658-.24-1.152-.512-1.47-.785-.32-.276-.409-.502-.409-.663s.088-.387.409-.662c.318-.274.812-.546 1.47-.785 1.312-.477 3.16-.783 5.23-.783s3.92.306 5.23.783c.66.24 1.153.511 1.471.785.32.275.408.5.408.662z'
        stroke='#2E7AEB'
        strokeWidth={1.115}
      />
    </svg>
  )
}
