import React from 'react'
import { cn } from '@/lib/utils'

type Props = {
  message?: string
  className?: string
}
export default function FormError({ message, className }: Props) {
  if (!message) {
    return
  }

  return (
    <>
      <span className={cn('text-[#dc2626] text-xs font-medium pt-1 block', className)}>{message}</span>
    </>
  )
}
