import * as React from 'react'
import { SVGProps } from 'react'

export function DamageIcon(props: SVGProps<any>) {
  return (
    <svg width={50} height={50} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.103 2.175l20.012 11.323v16.156l-3.227.616 1.036 1.874a.284.284 0 01-.007.295.297.297 0 01-.264.142l-2.003-.036 1.28 2.06a.283.283 0 01.028.25.29.29 0 01-.18.181l-2.818 1.027 1.885.88-1.397 2.978-14.345 8.117c-.32.181-.688.181-1.008 0L4.586 37a.961.961 0 01-.503-.856V13.498L24.095 2.175c.32-.18.687-.18 1.008 0z'
        fill='#F2D1A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.083 23.31v-7.836l20.516 9.633-2.958 6.996c-.213.503-.897.634-1.377.362L4.083 23.31z'
        fill='#ECBA78'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.598 25.107L4.082 13.498 1.124 18.52a.979.979 0 00.369 1.349l18.77 10.62c.48.272 1.1.11 1.377-.361l2.958-5.021z'
        fill='#F2D1A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.114 13.498v16.156l-2.823.539a.293.293 0 00-.216.164.283.283 0 00.008.268l.84 1.52a.284.284 0 01-.007.294.297.297 0 01-.264.143l-1.453-.027a.297.297 0 00-.268.149.285.285 0 00.004.301l.994 1.6a.282.282 0 01.028.25.29.29 0 01-.18.181l-2.149.783a.292.292 0 00-.196.265.29.29 0 00.172.28l.97.454c.15.07.214.244.145.391l-1.272 2.712-14.345 8.117c-.16.09-.332.136-.504.136V25.107l20.516-11.609zM49 31.163v6.491a.961.961 0 01-.503.855l-5.163 2.922 1.272-2.713a.294.294 0 00-.144-.39l-.971-.454a.29.29 0 01-.172-.28.292.292 0 01.196-.265l2.148-.783a.29.29 0 00.18-.18.283.283 0 00-.027-.252l-.994-1.6a.285.285 0 01-.004-.301.297.297 0 01.268-.149l1.453.027c.11.002.206-.05.264-.142.057-.093.06-.2.007-.295l-.84-1.52a.283.283 0 01-.008-.267.293.293 0 01.216-.165L49 31.163z'
        fill='#ECBA78'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.114 15.474v7.835l-16.181 9.156c-.481.272-1.165.141-1.377-.362l-2.958-6.997 20.516-9.632z'
        fill='#E3A76F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.598 25.107l20.516-11.609 2.958 5.022a.979.979 0 01-.369 1.349l-18.77 10.62c-.481.272-1.1.11-1.377-.361l-2.958-5.021z'
        fill='#ECBA78'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.083 13.498l20.516 11.609 20.516-11.609L24.599 1.89 4.083 13.498z'
        fill='#E3A76F'
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M4.083 13.498l20.516 11.609V1.89L4.083 13.498z' fill='#ECBA78' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.381 37.694l-3.07 1.736.337-.722a.781.781 0 00-.384-1.043l-.166-.077 1.376-.502a.782.782 0 00.48-.48.778.778 0 00-.072-.672l-.707-1.137 1.053.02h.015c.28 0 .54-.145.688-.38a.78.78 0 00.023-.787l-.764-1.375 1.191-.228v5.647zm1.319-7.215a.825.825 0 00-.669-.166l-3.226.616a.803.803 0 00-.574.439.773.773 0 00.019.713l.626 1.129-1.139-.02h-.014a.817.817 0 00-.702.396.767.767 0 00.014.805l.959 1.549-2.244.817a.789.789 0 00-.522.707.789.789 0 00.455.747l1.167.546-1.062 2.268a.777.777 0 00.19.915.811.811 0 00.944.1l5.669-3.206a.794.794 0 00.403-.684v-7.062a.787.787 0 00-.294-.61zm-10.836 4.77a.792.792 0 00-.526.704.787.787 0 00.46.75l1.167.544-.958 2.041L25.59 46.88V27.981l1.96 3.323a.79.79 0 00.697.395c.137 0 .28-.035.403-.106l15.846-8.965v6.3l-2.577.491a.803.803 0 00-.573.439.776.776 0 00.018.714l.622 1.129-1.134-.02h-.019a.81.81 0 00-.697.396.778.778 0 00.01.804l.962 1.548-2.244.82zM5.075 22.627l15.842 8.965a.823.823 0 001.105-.29l1.955-3.322v18.898L5.075 36.183V22.628zm-.512-8.124L1.906 19.01l19.12 10.818 2.657-4.508-19.12-10.818zm20.22-11.776L5.882 13.424 24.783 24.12 43.69 13.424 24.783 2.728zM47.66 19.01l-2.657-4.507-19.12 10.818 2.658 4.508L47.66 19.01zm-6.281 17.145l-.166-.077 1.375-.501a.806.806 0 00.48-.482.776.776 0 00-.072-.67l-.707-1.137 1.054.018.014.001c.28 0 .54-.144.688-.38a.768.768 0 00.019-.788l-.76-1.374 2.15-.41a.796.796 0 00.654-.776v-7.864l3.056-1.73a.778.778 0 00.294-1.08l-3.459-5.876a.784.784 0 00-.299-.289h.005L25.186 1.13a.825.825 0 00-.806 0L3.866 12.74a.771.771 0 00-.294.289L.108 18.905a.782.782 0 00.294 1.08l3.06 1.73V36.64c0 .282.152.543.404.684L24.38 48.933a.824.824 0 00.806 0l14.85-8.402a.764.764 0 00.327-.354l1.4-2.978a.782.782 0 00-.384-1.044z'
        fill='#000'
      />
    </svg>
  )
}
