import ContentPopover from '@/components/subComponents/popover/contentPopover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { assets } from '@/helpers/common'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

library.add(faQuestion)

const TabLuggage = function (props) {
  let className = 'single-tab-content row md:gap-[58px] lg:gap-[18px] xl:gap-[30px] luggage-tab' + props.className

  function getSelectOption(keyOne, keyTwo) {
    let selectedValue = props.formData.items[keyOne][keyTwo]
    let selectOptions = [...Array(11).keys()].map(i => {
      return (
        <option key={i} value={i}>
          {i}
        </option>
      )
    })

    return (
      <select
        style={{ cursor: 'pointer' }}
        className='input-field'
        value={selectedValue}
        onChange={e => onSelectItem(e, keyOne, keyTwo)}
      >
        {selectOptions}
      </select>
    )
  }

  function onSelectItem(event, keyOne, keyTwo) {
    let newData = { ...props.formData }

    newData.items[keyOne][keyTwo] = parseInt(event.target.value)
    props.setFormData(newData)

    props.removeItemCountError()
  }

  const errorClass = props.formErrors.hasOwnProperty('itemsCount') ? 'input-group has-error' : 'input-group'

  return (
    <div className={className}>
      <div className='md:basis-[84px] xl:basis-[109px] luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/small_l.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  22 x 15 x 11 inch <br />
                  56 x 38 x 28 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Luggage Type'</b> based on your packed luggage total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/small_luggage.png')} />
          </div>
          <div className='luggage-content'>
            <h5>Small Luggage</h5>
            <p>Max 22”x 15”x 11”</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={faQuestion} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 25 lbs / 11 kg</p>
          </div>
        </ContentPopover>
        <div className={errorClass}>{getSelectOption('luggage', 'small')}</div>
      </div>
      <div className='md:basis-[94px] xl:basis-[110px] luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/medium_l.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  26 x 18 x 11 inch <br />
                  66 x 46 x 28 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Luggage Type'</b> based on your packed luggage total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/medium_luggage.png')} />
          </div>
          <div className='luggage-content'>
            <h5>Medium Luggage</h5>
            <p>Max 26”x 18”x 11”</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={faQuestion} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 35 lbs / 16 kg</p>
          </div>
        </ContentPopover>
        <div className={errorClass}>{getSelectOption('luggage', 'medium')}</div>
      </div>
      <div className='md:basis-[99px] xl:basis-[117px] luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/standard_l.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  30 x 19 x 12 inch <br />
                  76 x 48 x 30 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Luggage Type'</b> based on your packed luggage total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/standard_luggage.png')} />
          </div>
          <div className='luggage-content'>
            <h5>Standard Luggage</h5>
            <p>Max 30”x 19”x 12”</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={faQuestion} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 50 lbs / 23 kg</p>
          </div>
        </ContentPopover>
        <div className={errorClass}>{getSelectOption('luggage', 'standard')}</div>
      </div>
      <div className='md:basis-[90px] xl:basis-[109px] luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/large_l.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  32 x 20 x 12 inch <br />
                  81 x 51 x 30 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Luggage Type'</b> based on your packed luggage total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/large_luggage.png')} />
          </div>
          <div className='luggage-content'>
            <h5>Large Luggage</h5>
            <p>Max 32”x 20”x 12”</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={faQuestion} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 60 lbs / 27 kg</p>
          </div>
        </ContentPopover>
        <div className={errorClass}>{getSelectOption('luggage', 'large')}</div>
      </div>
      <div className='md:basis-[94px] xl:basis-[109px] luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/x_large.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  34 x 22 x 14 inch <br />
                  86 x 56 x 36 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Luggage Type'</b> based on your packed luggage total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/x_large_luggage.png')} />
          </div>
          <div className='luggage-content'>
            <h5>X Large Luggage</h5>
            <p>Max 34”x 22”x 14”</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={faQuestion} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 75 lbs / 34 kg</p>
          </div>
        </ContentPopover>
        <div className={errorClass}>{getSelectOption('luggage', 'x_large')}</div>
      </div>
      <div className='md:basis-[104px] xl:basis-[115px] luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/oversize_l.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  38 x 23 x 14 inch <br />
                  97 x 58 x 36 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Luggage Type'</b> based on your packed luggage total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/oversize_luggage.png')} />
          </div>
          <div className='luggage-content'>
            <h5>Oversize Luggage</h5>
            <p>Max 38”x 23”x 14”</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={faQuestion} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 85 lbs / 39 kg</p>
          </div>
        </ContentPopover>
        <div className={errorClass}>{getSelectOption('luggage', 'oversize')}</div>
      </div>
    </div>
  )
}

export default TabLuggage
