import { useMutation } from '@tanstack/react-query'
import { StorageRoutes } from '@/api/routes'
import sendRequest from '@/api/sendRequest'

export const useStorageAddresses = () => {
  return useMutation({
    mutationKey: ['storage-addresses'],
    mutationFn: async ({ warehouseId }: { warehouseId?: string }) => {
      return await sendRequest({
        url: StorageRoutes.storageAddresses,
        queryParams: warehouseId
      })
    }
  })
}
