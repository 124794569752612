import React from 'react'
import ContentPopover from '@/components/subComponents/popover/contentPopover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { has, isEmpty } from 'lodash'
import { assets } from '@/helpers/common'

const TabGolfBag = function (props) {
  let className = !isEmpty(props.className)
    ? 'single-tab-content row gulf_bag_row ' + props.className
    : 'single-tab-content row gulf_bag_row'

  function getSelectOption(keyOne, keyTwo) {
    let selectOptions = [...Array(11).keys()].map(i => {
      return (
        <option key={i} value={i}>
          {i}
        </option>
      )
    })

    return (
      <select
        style={{ cursor: 'pointer' }}
        className='input-field'
        defaultValue={props.formData.items[keyOne][keyTwo]}
        onChange={e => onSelectItem(e, keyOne, keyTwo)}
      >
        {selectOptions}
      </select>
    )
  }

  function onSelectItem(event, keyOne, keyTwo) {
    let newData = { ...props.formData }

    newData.items[keyOne][keyTwo] = parseInt(event.target.value)
    props.setFormData(newData)

    props.removeItemCountError()
  }

  return (
    <div className={className}>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/small_g.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  50 x 12 x 10 inch <br />
                  127 x 30 x 25 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Golf Bag'</b> type based on your packed golf bag total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.{' '}
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/small_golf_box.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Small Travel Bag</h5>
            <p>Max 50" x 12" x 10"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 35 lbs / 16 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('golf_box', 'small')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/standard_g.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  52 x 12 x 11 inch <br />
                  132 x 30 x 28 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Golf Bag'</b> type based on your packed golf bag total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.{' '}
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/standard_golf_box.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Standard Travel Bag</h5>
            <p>Max 52" x 12" x 11"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 40 lbs / 18 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('golf_box', 'standard')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/large_g.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  52 x 13 x 12 inch <br />
                  132 x 33 x 30 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Golf Bag'</b> type based on your packed golf bag total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.{' '}
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/large_golf_box.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Large Travel Bag</h5>
            <p>Max 52" x 13" x 12"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 50 lbs / 23 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('golf_box', 'large')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/delux_g.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  53 x 14 x 13 inch <br />
                  135 x 36 x 33 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Golf Bag'</b> type based on your packed golf bag total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.{' '}
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/dellux_golf_box.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Deluxe Travel Bag</h5>
            <p>Max 53" x 14" x 13"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 55 lbs / 25 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('golf_box', 'delux')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/large_pro_g.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size: </span>
                <span className='text-secondary font-semibold'>
                  54 x 16 x 14 inch <br />
                  137 x 41 x 36 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Golf Bag'</b> type based on your packed golf bag total weight and size. If
                the weight and size exceed the limit amount, additional charges will apply.{' '}
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/large_pro_golf_box.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Large Pro Travel Bag</h5>
            <p>Max 54" x 16" x 14"</p>
            <p>
              Size Details{' '}
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 60 lbs / 27 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('golf_box', 'large_pro')}
        </div>
      </div>
    </div>
  )
}

export default TabGolfBag
