// @flow
import React from 'react'
import StepTitleWithInfo from '@/components/book-storage-facility/bookNowForm/includes/StepTitleWithInfo'

type Props = {
  children?: React.ReactNode
  stepData?: {
    step: number
    title: string
    info?: {
      content?: string | React.ReactNode
    }
  }
}

export default function StepCard({ stepData, children }: Props) {
  return (
    <div className='bg-[#EBF2F7] py-[9px] px-2 rounded-lg'>
      <StepTitleWithInfo
        step={stepData?.step}
        title={stepData?.title || ''}
        info={{
          content: stepData?.info?.content || ''
        }}
      />
      <div className='xxs:mt-2 xs:mt-3.5 mt-2'>{children}</div>
    </div>
  )
}
