/**
 * Author: Hridoy Ahmed
 * Website: hridoy.dev
 * Email: codewithhridoy@gmail.com
 * Last Updated At: 25 April 2024
 * Use default fetch for not using unnecessary packages like axios
 **/

import Configs from '@/configs'
import { Routes } from '@/v1/constants/routes'
import { STATUS } from '@/v1/constants/status'
import CookieKeys from '@/configs/caching/keys/cookie'
import { getEncryptedCookie, removeCookies } from '@/v1/lib/cookie'
import LocalStorageKeys from '@/configs/caching/keys/local-storage'
import { getLocalStorage, removeLocalStorages } from '@/v1/lib/local-storage'

import GoToDirectory from '@/components/common/GoToDirectory'
import { FetchHeaders, RequestParams } from '@/types/global'

const sendRequest = async ({
  url = '',
  payload = {},
  method = 'GET',
  queryParams = null,
  willRedirect = true,
  responseBlob = false,
  cachingMethod = 'cookie',
  needAuthorizedKey = false,
  requestHeaders = undefined,
  isFileRequest = false
}: RequestParams) => {
  const isCookieApplied = cachingMethod === 'cookie'

  const tokensToRemove: string[] = isCookieApplied
    ? [CookieKeys.tokenType, CookieKeys.expiresIn, CookieKeys.accessToken, CookieKeys.authorizedKey]
    : [
        LocalStorageKeys.tokenType,
        LocalStorageKeys.expiresIn,
        LocalStorageKeys.accessToken,
        LocalStorageKeys.authorizedKey
      ]

  /* Get Token and Authorized Key from local storage or cookies */
  const token = isCookieApplied
    ? getEncryptedCookie(CookieKeys.accessToken)
    : getLocalStorage(LocalStorageKeys.accessToken)

  const authorizedKey = isCookieApplied
    ? getEncryptedCookie(CookieKeys.authorizedKey)
    : getLocalStorage(LocalStorageKeys.authorizedKey)

  // let processedURL = responseBlob ? url : Configs.apiBaseURL + '/' + url
  let processedURL = Configs.apiBaseURL + '/' + url

  /** If url contains parameter like https://hridoy.com?currentPage=1&showPerPage=10&search=javascript*/
  if (queryParams != null) {
    processedURL += queryParams
  }

  const headers: FetchHeaders = {
    Accept: 'application/json',
    ...(!!token && { Authorization: `Bearer ${token}` }),
    ...(!!needAuthorizedKey && { 'Authorize-Code': authorizedKey }),
    ...(!isFileRequest && { 'Content-Type': 'application/json' }),
    ...requestHeaders
  }

  const removeCache = () => {
    if (isCookieApplied) {
      removeCookies(tokensToRemove)
    } else {
      removeLocalStorages(tokensToRemove)
    }
  }

  try {
    const result = await fetch(processedURL, {
      mode: 'cors',
      method: method,
      headers: headers,
      redirect: 'follow',
      referrerPolicy: 'origin',
      body: isFileRequest
        ? (payload as FormData)
        : // : !['GET', 'DELETE'].includes(method)
          !['GET'].includes(method)
          ? JSON.stringify(payload)
          : undefined
    })

    const statusCode = result.status

    // Better if gave code UNAUTHORIZED instead of PRECONDITION_FAILED from the API
    if (statusCode === STATUS.PRECONDITION_FAILED && willRedirect) {
      removeCache()
      GoToDirectory(Routes.LOGIN)
    }

    const data = responseBlob ? await result.blob() : await result.json()

    return {
      data,
      statusCode
    }
  } catch (error) {
    console.log(`Root API Error: ${error}`)

    return {
      data: null,
      status: 400,
      message: error
    }
  }
}

export default sendRequest
