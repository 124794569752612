import React from 'react'
import ContentPopover from '@/components/subComponents/popover/contentPopover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfoPopOver from '@/components/subComponents/popover'
import { has, isEmpty, sumBy } from 'lodash'
import { assets, onlyNumberAllow } from '@/helpers/common'

const TabBox = function (props) {
  let className = !isEmpty(props.className)
    ? 'single-tab-content box-tab-content row ' + props.className
    : 'single-tab-content box-tab-content row'

  function onSelectItem(event, keyOne, keyTwo) {
    let newData = { ...props.formData }

    newData.items[keyOne][keyTwo] = parseInt(event.target.value)
    props.setFormData(newData)

    props.removeItemCountError()
  }

  function getSelectOption(keyOne, keyTwo) {
    let selectOptions = [...Array(11).keys()].map(i => {
      return (
        <option key={i} value={i}>
          {i}
        </option>
      )
    })

    return (
      <select
        style={{ cursor: 'pointer' }}
        className='input-field'
        defaultValue={props.formData.items[keyOne][keyTwo]}
        onChange={e => onSelectItem(e, keyOne, keyTwo)}
      >
        {selectOptions}
      </select>
    )
  }

  function onChangeSpecial(e, keyOne, keyTwo, index) {
    let newData = { ...props.formData }
    if (String(e.target.value).length <= 3) {
      newData.items[keyOne + '_' + keyTwo][index][e.target.name] = Number(e.target.value)

      if (
        newData.items[keyOne + '_' + keyTwo][index][e.target.name] > 0 &&
        newData.items[keyOne + '_' + keyTwo][index]['qt'] < 1
      ) {
        newData.items[keyOne + '_' + keyTwo][index]['qt'] = 1
      }

      if (newData.items[keyOne + '_' + keyTwo][index][e.target.name] === 0) {
        let itemObj = newData.items[keyOne + '_' + keyTwo][index],
          sumValue = itemObj['wt'] + itemObj['wd'] + itemObj['he'] + itemObj['le']
        if (sumValue === 0) {
          newData.items[keyOne + '_' + keyTwo][index]['qt'] = 0
        }
      }

      newData.items[keyOne][keyTwo] = Number(sumBy(newData.items[keyOne + '_' + keyTwo], 'qt'))

      props.setFormData(newData)
    }
  }

  function specialBoxHasError(i, j) {
    if (!isEmpty(props.formErrors.box_special)) {
      let propertyKey = 'item-' + i
      if (has(props.formErrors.box_special, propertyKey)) {
        if (has(props.formErrors.box_special[propertyKey], j)) {
          return true
        }
      }
    }

    return false
  }

  function getSpecialBoxOption(keyOne, keyTwo) {
    return [...props.formData.items.box_special.keys()].map(i => {
      return (
        <tr key={i}>
          {['wt', 'wd', 'he', 'le'].map(j => {
            return (
              <td key={j}>
                <div className={specialBoxHasError(i, j) ? 'input-group has-error' : 'input-group'}>
                  <input
                    type='text'
                    name={j}
                    className='input-field'
                    placeholder={(() => {
                      switch (j) {
                        case 'wt':
                          return '0'
                        case 'wd':
                          return 'W'
                        case 'he':
                          return 'H'
                        case 'le':
                          return 'L'
                        default:
                          return j
                      }
                    })()}
                    autoComplete='off'
                    value={
                      props.formData.items[keyOne + '_' + keyTwo][i][j] !== 0
                        ? props.formData.items[keyOne + '_' + keyTwo][i][j]
                        : ''
                    }
                    onKeyDown={e => onlyNumberAllow(e, 3)}
                    onChange={e => onChangeSpecial(e, keyOne, keyTwo, i)}
                  />
                </div>
              </td>
            )
          })}
          <td>
            <div className={specialBoxHasError(i, 'qt') ? 'input-group has-error' : 'input-group'}>
              {
                <select
                  className='input-field'
                  name='qt'
                  value={
                    props.formData.items[keyOne + '_' + keyTwo][i]['qt'] !== 0
                      ? props.formData.items[keyOne + '_' + keyTwo][i]['qt']
                      : ''
                  }
                  onChange={e => onChangeSpecial(e, keyOne, keyTwo, i)}
                >
                  {[...Array(11).keys()].map(i => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
              }
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className={className}>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img src={assets('images/book-now/small_box.jpg')} alt={'small-box'} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size:</span>
                <span className='text-secondary font-semibold'>
                  15 x 15 x 15 inch <br />
                  38 x 38 x 38 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Shipping Box'</b>
                type based on your packed box total weight and size. If the weight and size exceed the limit amount,
                additional charges will apply. You can also enter the exact weight and size in the
                <b>'special box size'</b>.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img className='inline-block' src={assets('images/book-now/small_box.webp')} alt={'small-box'} />
          </div>
          <div className='luggage-content'>
            <h5>Small Box</h5>
            <p>Max 15" x 15" x 15"</p>
            <p>
              Size Details
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 25 lbs / 11 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('box', 'small')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img src={assets('images/book-now/standart_box.jpg')} alt={'standard'} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size:</span>
                <span className='text-secondary font-semibold'>
                  18 x 18 x 18 inch <br />
                  46 x 46 x 46 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Shipping Box'</b>
                type based on your packed box total weight and size. If the weight and size exceed the limit amount,
                additional charges will apply. You can also enter the exact weight and size in the
                <b>'special box size'</b>.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/standard_box.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Standard Box</h5>
            <p>Max 18" x 18" x 18"</p>
            <p>
              Size Details
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 42 lbs / 19 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('box', 'standard')}
        </div>
      </div>
      <div className='luggage-item text-center'>
        <ContentPopover
          popoverContent={
            <div className='box-info-content'>
              <img alt={''} src={assets('images/book-now/large_box.jpg')} />
              <p className='pl-16 relative inline-block mb-3'>
                <span className='text-red-500 font-semibold absolute left-0 top-0'>Max Size:</span>
                <span className='text-secondary font-semibold'>
                  20 x 20 x 20 inch <br />
                  51 x 51 x 51 cm
                </span>
              </p>
              <p>
                Please select the correct <b>'Shipping Box'</b>
                type based on your packed box total weight and size. If the weight and size exceed the limit amount,
                additional charges will apply. You can also enter the exact weight and size in the
                <b>'special box size'</b>.
              </p>
            </div>
          }
        >
          <div className='item-thumb'>
            <img alt={''} className='inline-block' src={assets('images/book-now/large_box.webp')} />
          </div>
          <div className='luggage-content'>
            <h5>Large Box</h5>
            <p>Max 20" x 20" x 20"</p>
            <p>
              Size Details
              <span className='icon'>
                <FontAwesomeIcon icon={['fas', 'question']} className={'w-[0.75em]'} />
              </span>
            </p>
            <p>Max 60 lbs / 27 kg</p>
          </div>
        </ContentPopover>
        <div className={has(props.formErrors, 'itemsCount') ? 'input-group has-error' : 'input-group'}>
          {getSelectOption('box', 'large')}
        </div>
      </div>
      <div className='luggage-item special-box w-6/12'>
        <div className='panel'>
          <div className='panel-heading'>
            <h3 className='text-center'>
              Special Size Box & Bag
              <InfoPopOver>
                <p className='text-sm13 text-center'>
                  {' '}
                  If your are not able to find the item to match your box or bag’s size and/or weight, please put the
                  weight, width, height, length and quantity of your box or bag here, so we can quote the shipping fee.
                </p>
              </InfoPopOver>
            </h3>
          </div>
          <div className='panel-body'>
            <table className='table'>
              <thead>
                <tr>
                  <td>Weight (lbs)</td>
                  <td>Size (inch)</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>{getSpecialBoxOption('box', 'special')}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabBox
