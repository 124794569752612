import { useMutation } from '@tanstack/react-query'

import sendRequest from '@/api/sendRequest'
import { PublicRoutes as Routes } from '@/api/routes'

export const useGetCountries = () => {
  return useMutation({
    mutationKey: ['getCountries'],
    mutationFn: () => {
      return sendRequest({
        url: Routes.countries
      })
    }
  })
}
