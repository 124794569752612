import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux'

import LocalStorageKeys from '@/configs/caching/keys/local-storage'
import Helpers from '@/helpers'
import { assets, decrypt, filterCheckPricePost } from '@/helpers/common'
import useLocalStorage from '@/hooks/use-local-storage'
import { CHECK_PRICE } from '@/router/allUrls'
import {
  FETCH_COUNTRIES,
  FETCH_WAREHOUSE,
  GET_EDIT_ORDER,
  GET_PRICE_LIST,
  GET_PRICE_LIST_RESET,
  RESET_CHECK_PRICE_DATA,
  SET_CHECK_PRICE_DATA,
  SHOW_INFO_MESSAGE
} from '@/store/constants/actionTypes'

import FormLeft from '@/components/book-storage-facility/bookNowForm/FormLeft'
import { OPTION } from '@/components/book-storage-facility/bookNowForm/includes/constant'
import PlayHowToVideo from '@/components/book-storage-facility/bookNowForm/includes/PlayHowToVideo'
import StepTitleWithInfo from '@/components/book-storage-facility/bookNowForm/includes/StepTitleWithInfo'
import TabBox from '@/components/book-storage-facility/bookNowForm/TabBox'
import TabGolfBag from '@/components/book-storage-facility/bookNowForm/TabGolfBag'
import TabLuggage from '@/components/book-storage-facility/bookNowForm/TabLuggage'
import TabSkis from '@/components/book-storage-facility/bookNowForm/TabSkis'
import { cn } from '@/lib/utils'

const DEFAULT_ITEMS = {
  luggage: {
    small: 0,
    medium: 0,
    standard: 0,
    large: 0,
    x_large: 0,
    oversize: 0
  },
  box: {
    small: 0,
    standard: 0,
    large: 0,
    special: 0
  },
  box_special: [
    { wt: 0, wd: 0, he: 0, le: 0, qt: 0 },
    { wt: 0, wd: 0, he: 0, le: 0, qt: 0 },
    { wt: 0, wd: 0, he: 0, le: 0, qt: 0 }
  ],
  golf_box: {
    small: 0,
    standard: 0,
    large: 0,
    delux: 0,
    large_pro: 0
  },
  skis: {
    small: 0,
    standard: 0,
    large: 0,
    delux: 0,
    large_pro: 0
  }
}

const defaultCountry = {
  country_id: 226,
  country: 'United States (USA)',
  iso2: 'US',
  iso3: 'USA',
  calling_code: '1'
}

const DEFAULT_FORM_DATA = {
  country_from: defaultCountry,
  country_to: false,
  warehouse: false,
  dropOff: false,
  pickUpDate: '',
  itemsCount: 0,
  state: '',
  state_to: '',
  serviceType: 1,
  items: DEFAULT_ITEMS,
  selectedStorageAddress: null,
  storageAddresses: []
}

const BookNowForm = function (props) {
  const router = useRouter()
  const { removeLocals } = useLocalStorage()
  const { edit_order, old_price_data, order_id, new_order } = router.query

  const [selectedTab, setSelectedTab] = useState('luggage')
  const [loading, setLoading] = useState(true)
  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA)

  const hasEditOrder = Boolean(edit_order)
  const hasOldPriceData = Boolean(old_price_data)

  const IS_SHIPPING_TO = formData?.serviceType === OPTION.SHIPPING_TO
  const IS_DROP_OFF = formData?.serviceType === OPTION.DROP_OFF
  const IS_SHIPPING_ONLY = formData?.serviceType === OPTION.SHIPPING_ONLY

  useLayoutEffect(() => {
    if (new_order === 'true') {
      resetForm()
    }
  }, [router])

  /* Remove session storage if coming from order from the storage */
  useEffect(() => {
    const hasParentItems = JSON.parse(sessionStorage.getItem('parent_items'))

    if (!!hasParentItems) {
      props.onResetCheckPriceData()
    }
  }, [])

  useEffect(() => {
    // temp disabled
    setFormData({
      ...formData,
      ...getStateFromSession()
    })
  }, [])

  useEffect(() => {
    props.onGetCountries()
    props.onGetWarehouses()
  }, [])

  useEffect(() => {
    if (hasEditOrder && router.isReady) {
      const orderId = decrypt(order_id)

      props.onGetEditOrder({ order_id: orderId })
    }
  }, [router, hasEditOrder, order_id])

  useEffect(() => {
    if (props.ORD.editOrder) {
      let orderData = props.ORD.editOrder
      let state = ''
      let box_special = [
        { wt: 0, wd: 0, he: 0, le: 0, qt: 0 },
        { wt: 0, wd: 0, he: 0, le: 0, qt: 0 },
        { wt: 0, wd: 0, he: 0, le: 0, qt: 0 }
      ]

      const incomingBoxSpecials = orderData?.items?.box_special ?? []

      if (incomingBoxSpecials?.length > 0) {
        incomingBoxSpecials.forEach((item, index) => {
          if (index < box_special.length) {
            //! Don't change this to just item otherwise you'll mutation error. If you want to change just item then make sure data will come in { wt: 0, wd: 0, he: 0, le: 0, qt: 0 } sequence from the api
            box_special[index] = {
              wt: item['wt'] ?? 0,
              wd: item['wd'] ?? 0,
              he: item['he'] ?? 0,
              le: item['le'] ?? 0,
              qt: item['qt'] ?? 0
            }
          }
        })
      }

      if (orderData?.city_form?.length > 0) {
        state = orderData?.city_form[0]
      } else {
        state = orderData?.pickup_postal_code
      }

      // console.log({ dd: orderData.items })

      let newState = {
        ...formData,
        state: state,
        country_from: orderData?.country_from[0],
        pickUpDate: orderData?.shipping_date,
        items: {
          luggage: {
            small: orderData?.items?.luggage?.small || 0,
            medium: orderData?.items?.luggage?.medium || 0,
            standard: orderData?.items?.luggage?.standard || 0,
            large: orderData?.items?.luggage?.large || 0,
            x_large: orderData?.items?.luggage?.x_large || 0,
            oversize: orderData?.items?.luggage?.oversize || 0
          },
          box: {
            small: orderData?.items?.box?.small || 0,
            standard: orderData?.items?.box?.standard || 0,
            large: orderData?.items?.box?.large || 0,
            special: orderData?.items?.box?.special || 0
          },
          box_special: box_special,
          golf_box: {
            small: orderData?.items?.golf_box?.small || 0,
            standard: orderData?.items?.golf_box?.standard || 0,
            large: orderData?.items?.golf_box?.large || 0,
            delux: orderData?.items?.golf_box?.delux || 0,
            large_pro: orderData?.items?.golf_box?.large_pro || 0
          },
          skis: {
            small: orderData?.items?.skis?.small || 0,
            standard: orderData?.items?.skis?.standard || 0,
            large: orderData?.items?.skis?.large || 0,
            delux: orderData?.items?.skis?.delux || 0,
            large_pro: orderData?.items?.skis?.large_pro || 0
          }
        }
      }

      if (orderData.storage_service === 0) {
        newState.country_to = orderData.country_to[0]
        newState.state_to = orderData.city_to[0]
        newState.serviceType = orderData.storage_service
      } else {
        newState.warehouse = orderData.storage[0]
        newState.storageAddresses = orderData.storage
        newState.selectedStorageAddress = orderData?.storage_address
      }

      setFormData(newState)
      setLoading(false)
    }
  }, [props.ORD.editOrder])

  useEffect(() => {
    if (props.ORD.countries !== false && props.ORD.warehouses !== false && !hasEditOrder) {
      setLoading(false)
    }
  }, [props.ORD.warehouses, props.ORD.countries])

  useEffect(() => {
    let newState = {
      ...formData,
      ...getStateFromSession() // temp disabled
    }

    if (!formData.country_from && !props.ORD.countries) {
      newState.country_from = props.ORD.countries[0]
      newState.country_to = props.ORD.countries[0]
    }
    setFormData(newState)
  }, [props.ORD.countries])

  useEffect(() => {
    if (props.ORD.priceListError !== false) {
      setLoading(false)
      let errors = props.ORD.priceListError.errors

      // console.log({ errors })

      setFormErrors({
        ...formErrors,
        ...errors
      })
      props.onShowInfoMessage({ type: 'error', text: props.ORD.priceListError.message })
      if (typeof window !== 'undefined' && window.innerWidth < 768) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
      props.onGetPriceListReset()
    }
  }, [props.ORD.priceListError])

  const getStateFromSession = () => {
    let orgData = JSON.parse(window.sessionStorage.getItem('orgData'))

    if (orgData) {
      return {
        ...formData,
        country_from: orgData.country_from,
        country_to: orgData.country_to,
        state: orgData.state,
        state_to: orgData.state_to,
        warehouse: orgData.warehouse,
        pickUpDate: orgData.pickUpDate,
        itemsCount: orgData.itemsCount,
        items: orgData.items,
        serviceType: orgData.serviceType,
        dropOff: orgData.dropOff,
        storageAddresses: orgData.storageAddresses,
        selectedStorageAddress: orgData.selectedStorageAddress
      }
    }
  }

  const countTotalItems = useCallback(() => {
    let totalItems = 0

    Object.values(formData.items['luggage']).forEach(val => {
      totalItems += val
    })

    Object.values(formData.items['box']).forEach(val => {
      totalItems += val
    })

    Object.values(formData.items['golf_box']).forEach(val => {
      totalItems += val
    })

    Object.values(formData.items['skis']).forEach(val => {
      totalItems += val
    })

    return totalItems
  }, [formData.items])

  const removeOrderFromStorageCache = () => {
    removeLocals([LocalStorageKeys.storageCheckedItems, LocalStorageKeys.shipOrPickUpFromStorage])
  }

  const resetForm = () => {
    removeOrderFromStorageCache()
    setFormData({
      ...DEFAULT_FORM_DATA,
      items: {
        luggage: {
          small: 0,
          medium: 0,
          standard: 0,
          large: 0,
          x_large: 0,
          oversize: 0
        },
        box: {
          small: 0,
          standard: 0,
          large: 0,
          special: 0
        },
        box_special: [
          { wt: 0, wd: 0, he: 0, le: 0, qt: 0 },
          { wt: 0, wd: 0, he: 0, le: 0, qt: 0 },
          { wt: 0, wd: 0, he: 0, le: 0, qt: 0 }
        ],
        golf_box: {
          small: 0,
          standard: 0,
          large: 0,
          delux: 0,
          large_pro: 0
        },
        skis: {
          small: 0,
          standard: 0,
          large: 0,
          delux: 0,
          large_pro: 0
        }
      }
    })
    // setFormData({ ...DEFAULT_FORM_DATA, items: DEFAULT_ITEMS })
  }

  const formReset = () => {
    router.replace({ query: {} }).then()
    /*if (hasOldPriceData) {

                            }*/

    props.onResetCheckPriceData()
    resetForm()
  }

  const formValidation = () => {
    let errors = {}

    // console.log({ formData })

    if (formData.country_from.country_id === 226 && Object.keys(formData.state).length <= 0) {
      errors.state = ['Zip Code or City is required']
    }

    if (IS_SHIPPING_ONLY && !formData.country_to) {
      errors.country_to = ['To Country is required']
    }

    if (IS_SHIPPING_ONLY && !formData.country_to) {
      errors.country_to = ['To Country is required']
    }

    if (formData.warehouse && !formData.selectedStorageAddress) {
      errors.selectedStorageAddress = ['Storage Address is required']
    }

    if (IS_SHIPPING_ONLY && formData.country_to.country_id === 226 && Object.keys(formData.state_to).length <= 0) {
      errors.state_to = ['To Zip Code or City is required']
    }

    if (Object.keys(formData.pickUpDate).length <= 0) {
      errors.pickUpDate = ['Shipping date is required']
    }

    if ((Object.keys(formData.warehouse).length <= 0 || !formData.warehouse) && IS_SHIPPING_TO) {
      errors.warehouse = ['Storage is required']
    }

    if (countTotalItems() === 0) {
      errors.itemsCount = ['Please select at list one luggage, box etc..']
      props.onShowInfoMessage({ type: 'error', text: 'Please select at list one luggage, box etc..' })
    }
    if (formData) {
      // errors.items = {box_special: []};
      let boxErrors = {}
      formData.items.box_special.forEach(function (item, index) {
        if (item.wt > 0 || item.wd > 0 || item.he > 0 || item.le > 0 || item.qt > 0) {
          let itemObj = {}
          if (item.wt === 0) {
            itemObj.wt = ['Weight is required']
          }
          if (item.wd === 0) {
            itemObj.wd = ['Width is required']
          }
          if (item.he === 0) {
            itemObj.he = ['Height is required']
          }
          if (item.le === 0) {
            itemObj.le = ['Length is required']
          }
          if (item.qt === 0) {
            itemObj.qt = ['Quantity is required']
          }

          if (Object.keys(itemObj).length > 0) {
            boxErrors['item-' + index] = itemObj
          }
        }
      })
      errors.box_special = boxErrors
    }

    if (Object.keys(errors.box_special).length === 0) {
      delete errors.box_special
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors({
        ...errors
      })
    } else {
      return true
    }

    return false
  }

  const getFilteredFormData = data => {
    let filterData = JSON.parse(JSON.stringify(data))

    Object.keys(filterData.items).map(item => {
      if (item === 'box_special') {
        if (filterData.items['box_special'][0]['qt'] === 0) delete filterData.items['box_special'][0]
        if (filterData.items['box_special'][1]['qt'] === 0) delete filterData.items['box_special'][1]
        if (filterData.items['box_special'][2]['qt'] === 0) delete filterData.items['box_special'][2]
      } else {
        Object.keys(filterData.items[item]).map(subItem => {
          if (filterData.items[item][subItem] === 0) {
            delete filterData.items[item][subItem]
          }
        })
      }
      if (Object.keys(filterData.items[item]).length === 0) {
        delete filterData.items[item]
      }
    })

    return filterData
  }

  const onSubmitHandler = async event => {
    event.preventDefault()
    setFormErrors({})
    let validated = formValidation()

    if (validated) {
      setLoading(true)

      props.onGetPriceListReset()

      let filteredData = getFilteredFormData(formData)

      props.onSetCheckPriceData({
        filteredData,
        orgData: formData
      })

      if (filteredData) {
        if (hasOldPriceData) {
          router.replace(`${CHECK_PRICE}?old_price_data=true`).then()
        }

        removeOrderFromStorageCache()

        const postData = filterCheckPricePost(formData)

        await props.onGetPriceList(postData)

        /* if (props.ORD.priceListSuccess) {

                                                        }*/

        /* TODO: Need to check API Error */

        if (hasEditOrder) {
          return router.push(`${CHECK_PRICE}?edit_order=true&order_id=${order_id}`).then()
        }

        router.push(CHECK_PRICE).then()
      }
    } else {
      Helpers.scrollToTop()
    }
  }

  const getOldDataStatus = (() => {
    return !!hasOldPriceData
  })()

  const handleSelectTab = key => {
    setSelectedTab(key)
  }

  const removeItemCountError = () => {
    const prevErrors = formErrors
    delete prevErrors.itemsCount
    setFormErrors(prevErrors)
  }

  const tabNav = [
    { id: 'tab_1', key: 'luggage', title: 'Luggage', icon: assets('images/book-now/tab_nav_icon_01.png') },
    { id: 'tab_2', key: 'box', title: 'Boxes', icon: assets('images/book-now/tab_nav_icon_02.png') },
    { id: 'tab_3', key: 'golf_box', title: 'Golf Bag', icon: assets('images/book-now/tab_nav_icon_03.png') },
    { id: 'tab_4', key: 'skis', title: 'Skis', icon: assets('images/book-now/tab_nav_icon_04.png') }
  ]

  let className =
    'order-form-section check-price-form-section pt-[24px] pb-[24px] lg-mn:pt-[32px] lg-mn:pb-[32px] sm-mx:pt-0 sm-mx:pb-0 ' +
    selectedTab

  const nav_tab_output = tabNav.map((item, key) => {
    let counts = 0
    if (Object.keys(formData.items[item.key]).length > 0) {
      counts = Object.values(formData.items[item.key]).reduce((acc, val) => acc + val, 0)
    } else {
      counts = formData.items[item.key]
    }

    return (
      <li
        key={item.title + key}
        className={selectedTab === item.key ? 'active ' + item.key : item.key}
        onClick={() => handleSelectTab(item.key)}
      >
        <div
          className={cn(
            'item-inner gap-1.5 !h-[83px] xl:!h-[80px]',
            selectedTab === item.key ? '!bg-tertiary-home' : '!bg-heading-home'
          )}
        >
          <div className='icon mt-1'></div>
          {/* <img alt=' src={item.icon} alt={item.title} />  */}
          <h4 className='title xs:!text-sm !text-sm xl:!text-xs'>
            <span className={'block'}>{item.title}</span>
            {IS_SHIPPING_ONLY && 'Shipping'}
            {IS_SHIPPING_TO && 'Storage'}
            {IS_DROP_OFF && 'Drop Off'}
          </h4>
        </div>
        {counts > 0 ? <div className='selected-item'>-{counts}-</div> : ''}
      </li>
    )
  })

  const videoTitle = IS_SHIPPING_ONLY
    ? 'Shipping Only'
    : IS_SHIPPING_TO
      ? 'Ship To Storage'
      : IS_DROP_OFF
        ? 'Drop Off To Storage'
        : ''

  return (
    <>
      <section className={className}>
        <div className='container  xxs:px-3.5 xs:px-4 md:px-0 xs:!max-w-full md:!max-w-[735px] lg:!max-w-[984px] xl:!max-w-[1170px]'>
          <div className='form-wrapper book-now-form w-full'>
            <form className='row' onSubmit={onSubmitHandler}>
              <div className='left-fields xs:max-w-full xs:mx-auto px-0 py-3 md:px-3 md:py-3 bg-white md:!justify-start md:gap-3 lg:!justify-between lg:gap-0'>
                <FormLeft
                  loading={loading}
                  formData={formData}
                  setFormData={setFormData}
                  countries={props.ORD.countries}
                  warehouses={props.ORD.warehouses}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                  oldDataStatus={getOldDataStatus}
                />
                <div className='hidden md:block lg:hidden'>
                  <PlayHowToVideo
                    className={'mx-auto'}
                    title={
                      <>
                        <span className={'block'}>How It Works?</span>
                        <span className={'block'}>{videoTitle}</span>
                      </>
                    }
                  />
                </div>
              </div>
              <div
                className={
                  loading
                    ? 'right-fields md:px-3 md:py-4  xl:px-3 xl:py-4 relative'
                    : 'right-fields md:px-3 md:py-4  xl:px-3 xl:py-4'
                }
              >
                {loading && (
                  <div className='loader'>
                    <img alt={''} src={assets('images/loading.svg')} />
                  </div>
                )}
                <div className='input-group tab-nav-warpper ipad-mini-h:mb-5'>
                  <StepTitleWithInfo
                    className={'justify-start md:items-start lg:items-center h-auto lg:h-5'}
                    contentClassName={'justify-start'}
                    step={IS_SHIPPING_ONLY || IS_SHIPPING_TO ? 5 : 4}
                    title={
                      <>
                        <span className={''}>Select Your Items</span>
                        <span className={'hidden lg:inline'}>(Same or Mix up to 20 Different Items)</span>
                      </>
                    }
                    info={{
                      content: (
                        <>
                          <ul className='text-sm13'>
                            <li>1) Please click the blue icons below to see different types of items.</li>
                            <li>
                              2) Please select the item quantity from the drop-down list under various sizes of each
                              item.
                            </li>
                          </ul>
                        </>
                      )
                    }}
                  />
                  <p className={'ml-[58px] xxs:!text-sm xs:!text-base !text-sm text-[#333] font-semibold lg:hidden'}>
                    (Same or Mix up to 20 Different Items)
                  </p>
                  <ul className='tab-nav'>{nav_tab_output}</ul>
                </div>

                <AnimatePresence mode={'wait'}>
                  <LazyMotion features={domAnimation}>
                    <m.div
                      className='tab-content-wrapper'
                      key={selectedTab ? selectedTab : 'empty'}
                      animate={{ opacity: 1, y: 0 }}
                      initial={{ opacity: 0, y: 20 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.15 }}
                      mode={'wait'}
                    >
                      {selectedTab === 'luggage' && (
                        <>
                          <TabLuggage
                            key={formData.items}
                            formData={formData}
                            formErrors={formErrors}
                            removeItemCountError={removeItemCountError}
                            setFormData={setFormData}
                            // className={selectedTab === 'luggage' ? ' active' : ''}
                          />
                        </>
                      )}

                      {selectedTab === 'box' && (
                        <>
                          <TabBox
                            key={formData.items}
                            // className={selectedTab === 'box' ? ' active' : ''}
                            formData={formData}
                            formErrors={formErrors}
                            setFormData={setFormData}
                            removeItemCountError={removeItemCountError}
                          />
                        </>
                      )}

                      {selectedTab === 'golf_box' && (
                        <>
                          <TabGolfBag
                            key={formData.items}
                            // className={selectedTab === 'golf_box' ? ' active' : ''}
                            formData={formData}
                            formErrors={formErrors}
                            setFormData={setFormData}
                            removeItemCountError={removeItemCountError}
                          />
                        </>
                      )}

                      {selectedTab === 'skis' && (
                        <>
                          <TabSkis
                            key={formData.items}
                            // className={selectedTab === 'skis' ? ' active' : ''}
                            formData={formData}
                            formErrors={formErrors}
                            setFormData={setFormData}
                            removeItemCountError={removeItemCountError}
                          />
                        </>
                      )}
                    </m.div>
                  </LazyMotion>
                </AnimatePresence>

                <div className='form-bottom row justify-between items-center md-mx:justify-center gap-6 lg:gap-0'>
                  <div className='left-col md-mx:w-full lg-mx:w-7/12 md-mx:text-center md-mx:mb-3 order-2 lg:order-1'>
                    <div className='block md:hidden lg:block'>
                      <PlayHowToVideo
                        className={'max-w-[295px] lg:max-w-[204px] mx-auto lg:ml-0'}
                        title={
                          <>
                            <span className={'block'}>How It Works?</span>
                            <span className={'block'}>{videoTitle}</span>
                          </>
                        }
                      />
                    </div>
                  </div>
                  <div className='xs:max-w-[295px] lg:max-w-auto xs:mx-auto right-col md-mx:w-full lg-mx:w-5/12 buttons-group flex justify-end md-mx:justify-center order-1 lg:order-2'>
                    <button
                      className='btn btn-primary md-mn:w-[92px] w-[117px] !bg-heading-home'
                      type='reset'
                      onClick={formReset}
                    >
                      Reset
                    </button>
                    <button className='btn btn-primary md-mn:w-[134px] w-[166px]' type='submit'>
                      Check Price
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => {
  return {
    ORD: state.orderReducer
  }
}
const mapDispatchToProps = dispatch => ({
  onGetWarehouses: () => dispatch({ type: FETCH_WAREHOUSE }),
  onGetCountries: () => dispatch({ type: FETCH_COUNTRIES }),
  onGetEditOrder: data => dispatch({ type: GET_EDIT_ORDER, data }),
  onResetCheckPriceData: () => dispatch({ type: RESET_CHECK_PRICE_DATA }),
  onSetCheckPriceData: data => dispatch({ type: SET_CHECK_PRICE_DATA, data }),
  onGetPriceListReset: () => dispatch({ type: GET_PRICE_LIST_RESET }),
  onGetPriceList: data => dispatch({ type: GET_PRICE_LIST, data }),
  onShowInfoMessage: options => dispatch({ type: SHOW_INFO_MESSAGE, options })
})
export default connect(mapStateToProps, mapDispatchToProps)(BookNowForm)
