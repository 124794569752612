const getLocalStorage = (key: string): any => {
  const item = localStorage.getItem(key)

  return item ? JSON.parse(item) : null
}

const setLocalStorage = (key: string, value: any): void => localStorage.setItem(key, JSON.stringify(value))

const removeLocalStorage = (key: string): void => localStorage.removeItem(key)

const removeLocalStorages = (keys: string[]): void => keys.forEach(key => localStorage.removeItem(key))

export { getLocalStorage, setLocalStorage, removeLocalStorage, removeLocalStorages }
