import * as React from 'react'
import { SVGProps } from 'react'

export function CheckIconSquare(props: SVGProps<any>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#a)'>
        <path
          d='M24 2.75A2.75 2.75 0 0021.25 0H2.75A2.75 2.75 0 000 2.75v18.5A2.75 2.75 0 002.75 24h18.5A2.75 2.75 0 0024 21.25z'
          fill='#2ed573'
        />
        <path
          d='M20.576 6.414a1.386 1.386 0 010 1.996l-9.404 9.176a1.46 1.46 0 01-1.023.414c-.37 0-.74-.139-1.023-.414l-4.702-4.588a1.386 1.386 0 010-1.996 1.47 1.47 0 012.046 0l3.679 3.59 8.381-8.178a1.47 1.47 0 012.046 0'
          fill='#FAFAFA'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
