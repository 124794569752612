const accessToken = process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY || 'token'
const tokenType = process.env.NEXT_PUBLIC_TOKEN_TYPE_KEY || 'token_type'
const expiresIn = process.env.NEXT_PUBLIC_EXPIRES_IN_KEY || 'expires_in'
const authorizedKey = process.env.NEXT_PUBLIC_AUTHORZED_KEY || 'ak'
const refreshToken = process.env.NEXT_PUBLIC_REFRESH_TOKEN_KEY || 'refresh_token'
const checkAuth = process.env.NEXT_PUBLIC_CHECK_AUTH || 'is_authenticated'
const adminInfo = process.env.NEXT_PUBLIC_CHECK_AUTH || 'admin_info'

/* ===== ===== */
const countries = process.env.NEXT_PUBLIC_COUNTRY_KEY || 'countries'
const storageCheckedItems = process.env.NEXT_PUBLIC_STORAGE_CHECKED_ITEMS || 'checked_items'
const shipOrPickUpFromStorage = process.env.NEXT_PUBLIC_SHIP_OR_PICKUP_FROM_STORAGE || 'ship_or_pickup_from_storage'

export const LocalStorageKeys = {
  accessToken,
  tokenType,
  expiresIn,
  authorizedKey,
  refreshToken,
  checkAuth,
  adminInfo,
  countries,
  storageCheckedItems,
  shipOrPickUpFromStorage
}

export default LocalStorageKeys
