export const OrderRoutes = {
  /* GET */

  /* POST */

  /* PUT */
  updateDelivery: 'order/update-delivery-label-info/'

  /* PATCH */

  /* DELETE */
}
